import CampanaGraphQL from "js/graphql/resolvers/campana.resolver";

export const fetchParametrosVisuales = async (idPrograma, client) => {
  const { data } = await client.query({
    query: CampanaGraphQL.queries.getParametrosCampana,
    fetchPolicy: "network-only",
    variables: {
      idPrograma: parseInt(idPrograma) || idPrograma
    }
  });

  let parametrosVisuales;

  if (data) {
    const parametros = data.getParametrosCampana;

    if (parametros) {
      parametrosVisuales = JSON.parse(parametros.parametrosVisuales);
    }
  }

  return parametrosVisuales;
};

export const getBanners = (banners, isClient) => {
  let clientBanners;
  let role = isClient ? "client" : "admin";

  if (banners) {
    clientBanners = { ...banners };
    let { contenidos } = clientBanners;
    let clientContenidos = contenidos.filter(contenido1 => {
      let {
        texto: { contenido: rol }
      } = contenido1;
      return rol === role;
    });

    if (clientContenidos.length > 0) {
      clientBanners.filaTipo = clientContenidos.length;
      clientBanners.contenidos = clientContenidos;
    } else {
      clientBanners.visible = false;
    }
  }
  return clientBanners;
};

export const getBannersCampanas = (banners, excluded) => {
  let clientBanners;

  if (banners) {
    clientBanners = { ...banners };
    let { contenidos } = clientBanners;
    let clientContenidos = contenidos.filter(content => {
      let {
        texto: { contenido: rol }
      } = content;
      if (rol) {
        return !excluded.includes(rol);
      } else return true;
    });

    if (clientContenidos.length > 0) {
      if (clientBanners.filaTipo)
        clientBanners.filaTipo = clientContenidos.length;
      if (clientBanners.contenidos) clientBanners.contenidos = clientContenidos;
    } else {
      if (clientBanners.visible) clientBanners.visible = false;
    }
  }
  return clientBanners;
};

export const disableLink = banners => {
  let clientBanners;
  if (banners) {
    let { contenidos } = banners;
    clientBanners = contenidos.map(contenido => {
      let { link } = contenido;
      contenido.link = link ? "" : link;
      return contenido;
    });
  }
  return clientBanners;
};

export const cleanText = banners => {
  let clientBanners;
  if (banners) {
    let { contenidos } = banners;
    clientBanners = contenidos.map(contenido1 => {
      let { texto } = contenido1;
      delete texto.contenido;
      contenido1.texto = texto;
      return contenido1;
    });
  }
  return clientBanners;
};
